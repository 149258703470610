<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="sign_page">
            <div class="form_wrap">
              <div class="form">
                <ul class="form_ul">
                  <li class="form_li">
                    <div class="form_ttl">비밀번호</div>
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input
                            type="password"
                            placeholder="비밀번호는 4자리 이상 입력해 주세요."
                            v-model="pw"
                          />
                        </label>
                      </div>
                      <div class="hint" :class="{ on: valid.pw.$error }">
                        <p>{{ msg.pw }}</p>
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">비밀번호 확인</div>
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input
                            type="password"
                            placeholder="비밀번호는 4자리 이상 입력해 주세요."
                            v-model="confirmPw"
                          />
                        </label>
                      </div>
                      <div class="hint" :class="{ on: valid.confirmPw.$error }">
                        {{ msg.confirmPw }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="page_btn">
          <div class="btn_wrap">
            <button class="btn" :class="{ on: isFillUp }" @click="fnChange">
              비밀번호 변경
            </button>
            <!--입력되면 on-->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { reactive, toRefs, watch, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import CONFIRM from '@/components/common/modal/alert/confirm'

export default {
  name: 'find-reset',
  description: '비밀번호 변경 페이지',
  setup(props) {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const query = route.query
    const params = Object.assign({}, props)

    const state = reactive({
      userNo: query.userNo,
      pw: '',
      confirmPw: '',
      msg: {
        pw: '',
        confirmPw: ''
      },
      isFillUp: false,
      params
    })

    watch(state, async () => {
      if (
        !proxy.$Util.isEmpty(state.pw) &&
        !proxy.$Util.isEmpty(state.confirmPw)
      ) {
        state.isFillUp = true
      } else {
        state.isFillUp = false
      }
    })

    const rules = {
      pw: {
        test: function(val) {
          if (proxy.$Util.isEmpty(val)) {
            state.msg.pw = '비밀번호를 입력해 주세요.'
            return false
          }
          if (val.length < 4 || val.length > 11) {
            state.msg.pw = '비밀번호는 4자리 이상 12자리 이하 입력해야 합니다.'
            return false
          }
          return true
        }
      },
      confirmPw: {
        test: function(val) {
          if (proxy.$Util.isEmpty(val)) {
            state.msg.confirmPw = '비밀번호를 입력해 주세요.'
            return false
          }
          if (val.length < 4 || val.length > 11) {
            state.msg.confirmPw =
              '비밀번호는 4자리 이상 12자리 이하 입력해야 합니다.'
            return false
          }
          if (state.pw !== state.confirmPw) {
            state.msg.confirmPw = '비밀번호가 일치하지 않습니다.'
            return false
          }
          return true
        }
      }
    }
    const valid = useVuelidate(rules, state)
    const fnChange = async () => {
      valid.value.$touch()
      if (valid.value.$invalid) return
      const payload = {}
      payload.component = CONFIRM
      payload.layout = {
        title: '알림',
        contents: '변경하시겠습니까?'
      }
      payload.data = {}
      payload.callBackDone = async () => {
        const params = {
          userNo: query.userNo,
          newPassword: state.pw
        }
        const res = await proxy.$SignSvc.updatePassword(params)
        if (res.code === 1) {
          alert('비밀번호가 변경되었습니다.')
          await router.push({ path: '/sign/in' })
        }
      }
      payload.callBackCancel = proxy => {
        proxy.$emit('close')
      }
      await store.dispatch('layout/pushModalAlertComponent', payload)
    }

    return { ...toRefs(state), fnChange, valid }
  }
}
</script>

<style scoped></style>
